@import '../../../../sass/variables';

.select-async-custom-wr {
  position: relative;
  
  &--disabled {
    
  }

  &__disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    cursor: not-allowed;
  }
  
  &__error-messages {
    margin-top: 5px;
  }

  &__error-message {
    color: $red-main;
  }
}

.select-async-custom {

  &:hover {
    .select-async-custom__control {
      cursor: pointer;
    }
  }
  
  &--error {
    
  }

  &__control {
    box-shadow: none !important;
    transition: all 0.25s ease-in-out !important;
    
    &:hover {
      border-color: $blue-main !important;
    }
  }

  &__clear-indicator {
    transition: all 0.25s ease-in-out !important;
    
    &:hover {
      color: $red-darker !important;
    }
  }

  &__dropdown-indicator {
    transition: all 0.25s ease-in-out !important;
    
    &:hover {
      color: $blue-main !important;
    }
  }
  
  &__menu {
    z-index: 200 !important;
  }
  
  &__menu-list {
    height: 300px;
    overflow-y: scroll !important;
  }
  
  &__option {
    cursor: pointer !important;
    transition: all 0.25s ease-in-out !important;
  }
  
  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &--show {
      opacity: 1;
      visibility: visible;
    }
  }
}
