.prioritet-container {
    position: absolute;
    width: 35px;
    display: flex;
    gap: 1px;
    top: 2px;
    left: -6px;
    height: 10px;
    align-items: center;
    justify-content: center; }
.react-calendar__tile--active .prioritet-container {
    position: absolute;
    width: 35px;
    display: flex;
    gap: 1px;
    top: 2px;
    left: -6px;
    background: white;
    height: 10px;
    align-items: center;
    justify-content: center; }

.red_task {
    width: 6px;
    height: 6px;
    background: #F44336;
    border-radius: 10px; }

.orange_task {
    width: 6px;
    height: 6px;
    background: #FF9900;
    border-radius: 10px; }

.yellow_task {
    width: 6px;
    height: 6px;
    background: #FFD600;
    border-radius: 10px; }

.task {
    width: 6px;
    height: 6px;
    background: #D3D3D3;
    border-radius: 10px; }

.event_cube {
    width: 6px;
    height: 6px;
    background: #AECBEC;
    border-radius: 10px; }

.gray_cube {
    width: 6px;
    height: 6px;
    background: #AECBEC;
    border-radius: 10px; }

.meet_cube {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background: #9ADCF2; }

.week-start {
    pointer-events: none;
    width: 415px;
    height: 70px;
    display: flex;
    position: absolute;
    top: -38px;
    left: -30px;
    z-index: 1; }

.week-start-right {
    width: 17px;
    background: rgba(2, 167, 240, 1); }
.week-start-left {
    width: 100%;
    opacity: 0.3;
    background: rgba(2, 167, 240, 1); }

.week-start:hover::before {
    display: inline-block;
    content: '';
    background: rgba(2, 167, 240, 1);
    width: 17px; }

.dhx_cal_header {
    background: none; }

.mini_calendar {
    .react-calendar__navigation {
        display: none !important; } }

.scheduler-date-box-month {
    display: flex;
    width: 100%;
    max-height: 40px;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #929090; }

.scheduler-prev-month {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }

.scheduler-date-month {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #292A34;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    white-space: nowrap;
    justify-content: center; }

.scheduler-next-month {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }

