@import '../../../sass/mixins';

.table_search {
    cursor: pointer;
    background: #4BB5EB;
    transition: background .2s ease, width .2s ease;
    grid-column: 10;
    border-radius: 8px;
    padding-left: 31px;
    position: relative;
    overflow: hidden;
    color: white;
    @include size(82px, 32px);
    @include flex(flex-start, center);
    width: 288px;
    height: 34px;
    input {
        color: white;
        background: transparent;
        width: 132px; }
    &::before {
        content: '';
        transform: translateY(-50%);
        background: url('../../../img/search.svg');
        background-position: -3px -3px;
        background-size: 21px;
        background-repeat: no-repeat;
        @include absolute(50%, initial, initial, 8px);
        @include size(15px, 15px); }

    &:hover {
        background: #4BB5EB; }
    &.in_search_mode {
        color: white;
        width: 288px;
        height: 34px;
        background: #4BB5EB;
        input {
            color: white;
            background: transparent;
            width: 288px;
            height: 34px; } }
    .close_search {
        background-image: url('../../../img/x.svg');
        background-position: -4px -4px;
        margin-right: 8px;
        background-size: 16px;
        background-repeat: no-repeat;
        opacity: .8;
        margin-left: 8px;
        cursor: pointer;
        @include size(10px, 10px);
        &:hover {
            opacity: 1; } } }

