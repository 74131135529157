@import '../../../sass/mixins';
@import '../../../sass/variables';


.priority {
    height: 18px;
    @include flex(false, center);
    & > div {
        font-size: 15px;
        color: $blue-main;
        letter-spacing: 0.2px;
        line-height: 18px; }
    &.priority_urgent {
        //& > div
        color: black; }
    & > .priority_img {
        height: 26px;
        width: 20px;
        background-image: url("../../../img/prior_1.svg");
        &.priority_7 {
            background-image: url("../../../img/prior_2.svg"); }
        &.priority_2 {
            @extend .priority_7; }
        &.priority_8 {
            background-image: url("../../../img/prior_3.svg"); }
        &.priority_3 {
            @extend .priority_8; }
        &.priority_9 {
            background-image: url("../../../img/prior_3.svg"); }
        &.priority_4 {
            @extend .priority_9; } } }
