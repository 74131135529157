@import '../../../../sass/variables';

.tasks-table {
  position: relative;
  
  &__preloader-wr {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-in-out;
    will-change: opacity, visibility;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    backdrop-filter: blur(1px);
    background: rgba(255, 255, 255, 0.9);

    &--show {
      opacity: 1;
      visibility: visible;
    }

    .preloader-local {
      width: 42px;
      height: 42px;
      position: absolute;
      top: 50%;
      transform: translateY(-143px);

      > div {
        border-color: $blue-main transparent transparent transparent;
        width: 42px;
        height: 42px;
        border-width: 3px;
      }
    }
  }
  
  &__content {
    width: 100%;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    will-change: opacity, visibility;
    opacity: 0;
    visibility: hidden;
    
    &--show {
      opacity: 1;
      visibility: visible;
    }
  }
  
  &__default-message {
    padding: 24px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all 0.25s ease-in-out;

    &--show {
      opacity: 1;
      visibility: visible;
    }
  }
}
