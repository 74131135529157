@import '../../../../../../../sass/variables';

.meeting-members-items {
  position: relative;
  
  &__logo-wr {
    height: 34px;
    position: relative;
  }

  &__preloader {
    background: rgb(244, 244, 244);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &--show {
      opacity: 1;
      visibility: visible;
    }

    .preloader-local {
      opacity: $preloaderSlowReqOpacity;

      > div {
        border-color: $blue-main transparent transparent transparent;
      }
    }
  }
}
