@import '../../../sass/mixins';
@import '../../../sass/variables';

.dhx_wrapper {
  display: flex;
  height: 100%; }

.dhx_cal_navline {
  display: none !important;
  position: absolute !important; }

.dhx_cal_tab_last {
  margin: 0 !important; }

.dhx_cal_nav_button_custom {
  &:nth-child(2) {
    margin-left: 10px;
    cursor: default;
    color: gray;
    box-shadow: -1px 0 0 gray !important;
    border-color: gray;

    &:hover {
      background: none; } } }

.dhx_cal_tab {
  border: 1px solid #0288d1;
  border-radius: 10px !important;
  font-size: 14px !important; }

.dhx_cal_date {
  font-size: 14px !important; }
.dhx_cal_navbar_row {
  gap: 10px; }

.dhx_date_container {
  border: 1px solid rgba(170, 170, 170, 1); }

.dragging {
  cursor: grab; }

.dhx_scale_hour {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: rgba(41, 42, 52, 0.5);
  position: relative;
  line-height: 0;
  overflow: visible;
  border-bottom: none;
  background: #F4F4F4;
  &:first-child {
    font-size: 0;
    &:before {
      display: none; } }
  &:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 1px;
    background: #E1E1E1;
    right: 0;
    top: -1px; } }

.task_info {
  background: #FFFFFF;
  flex-direction: column;
  color: $black-darkest !important;
  @include flex;

  .custom_select {
    .btn.secondary {
      border: 1px solid rgba(27, 170, 240, 0.3);
      background: rgba(27, 170, 240, 0.05);

      input[type=text] {
        background: rgba(27, 170, 240, 0.005); } } }

  .tabs_desc {
    position: relative;
    .first_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .toggler {
        .toggler_elem {
          padding: 8px; } }

      .iconsBar {
        @include flex(space-between);

        .count {
          font-size: 14px;
          line-height: 32px;
          font-weight: 400;
          cursor: default; }

        .attachments {
          @include flex();

          .icon {
            margin-left: 2px;
            @include size(32px, 32px);
            background-image: url('../../../img/attach-blue_darker.svg');
            background-position: -3px 0;
            background-size: 30px;
            background-repeat: no-repeat; } }

        .comments {
          @include flex();

          .icon {
            margin-left: 2px;
            @include size(32px, 32px);
            background-image: url('../../../img/comment.svg');
            background-position: 4px -2px;
            background-size: 23px;
            background-repeat: no-repeat; } }

        .bell {
          margin-left: 8px;
          @include size(32px, 32px);
          background-image: url('../../../img/bell-blue_darker.svg');
          background-position: -3px 0;
          background-size: 30px;
          background-repeat: no-repeat; } }

      .task_status_wrapper {
        .task_status {
          width: 140px;
          height: 32px;
          line-height: 32px;

          &.disabled {
            cursor: default; } } }

      .custom_select {
        &.disabled {
          div {
            cursor: default; } } } }

    .second_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .input_div {
        width: 100%; } } }

  .comment_list {
    margin: 0; }

  &.is_shown {
    right: 0; }

  .grid {
    grid-template-columns: 120px 170px 1fr 1fr;
    column-gap: 16px;
    padding: 0; }

  .close_btn {
    margin: 0;
    @include absolute(10px, 24px); }

  .taskInfoPlateBottomRowButtons {
    grid-column: 1/5;
    @include flex(flex-start); }

  .task_name {
    textarea {
      margin-bottom: 16px;
      font-family: Roboto, sans-serif;
      cursor: pointer;
      transition: .5s ease;
      font-size: 14px;
      border: 1px solid #E0E0E0;

      &:focus {
        border: 1px solid #1BAAF0;
        box-shadow: 0 0 0 2px #99D7F5;
        padding: 0; }

      &:not(:empty) {
        background-color: #F3FAFE;
        border: 1px solid #99D7F5; }

      &:focus {
        cursor: text; }

      &.disabled {
        background: white; } }

    .close_button {
      position: relative;
      width: 100%;
      margin-bottom: 8px;

      .close_panel {
        background-image: url('../../../img/x.svg');
        width: 24px;
        height: 24px;
        cursor: pointer; }

      .MuiSvgIcon-root {
        color: grey;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0; } }

    textarea {
      border: 1px solid #E0E0E0;
      width: 100%;
      resize: none;
      font-size: 14px;
      padding-bottom: 3px;

      &:focus {
        border: 1px solid $blue-main;
        padding: 0; }

      &:not(:empty) {
        background-color: #F3FAFE;
        border: 1px solid #99D7F5; } } }

  .task_description {
    color: #212121;
    opacity: .8;
    max-height: 54px;
    font-size: 15px;
    line-height: 18px;
    min-height: 18px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;
    @include ellipsis(3); }

  .toggler {
    height: 32px;

    .toggler_elem {
      line-height: 14px;
      font-size: 13px;
      padding: 8px 0; }

    &.priority_9 {
      border-color: #FF0F00; }

    &.priority_4 {
      border-color: #FF0F00; } }

  .priority_select {
    .priority {
      height: 32px;
      border: 1px solid $blue-main;
      border-radius: 6px;
      justify-content: center;
      cursor: pointer;
      padding: 8px;

      .priority_img {
        width: 16px !important;
        height: 16px !important;
        background-position: -4px -4px;
        margin-right: 4px;
        background-repeat: no-repeat; } }

    .custom_select_item {
      color: $blue-main;
      position: relative;
      font-size: 15px;

      &.selected_item {
        opacity: .6; }

      &:last-of-type {
        color: #FF0F00; } } }

  .task_date_time {
    grid-column: 2/4; }

  .info_tab {
    row-gap: 8px;
    margin-top: 30px;
    .dependencies {
      grid-column: 2/5;

      .firstRow {
        display: flex;

        .toggler {
          margin: 4px 0;

          .toggler_elem {
            padding: 8px 8px; } } } }

    .taskWorkLoad {
      grid-column: 2/5;
      .innerComponents {
        display: flex;
        align-items: center;

        .input_div {
          width: 98px;
          height: 40px;
          padding: 0 12px;

          &.disabled {
            background: rgba(27, 170, 240, 0.05); } }

        .toggler {
          margin: 0 8px;
          width: 153px;
          height: 40px;

          .toggler_elem {
            padding: 12px 8px; } } } }

    .date_time {
      div {
        background: rgba(27, 170, 240, 0.05);
        font-size: 14px; } }

    .input_with_unit {
      div {
        background: rgba(27, 170, 240, 0.05); } }

    .title {
      grid-column: 1/2;
      color: #5F6368;
      display: flex;
      line-height: 26px;
      font-size: 13px; }

    .custom_select {
      grid-column: 2/5;
      flex-grow: 0 !important;

      &.author {
        width: 234px; } }

    .executor_with_delegate {
      grid-column: 1/5;
      grid-template-columns: 112px 170px 1fr 1fr;

      .executor_select {
        grid-column: 1/5;
        grid-template-columns: 112px 170px 1fr 1fr;
        justify-content: center;
        column-gap: 24px;

        .title {
          grid-column: 1/2;
          justify-self: initial;
          align-self: center; }

        .executor_select_with_btn {
          justify-content: left;
          grid-column: 2/5;

          .input_div {
            width: 234px; }

          .btn.secondary {
            width: 234px; }

          p {
            color: $blue-main;
            margin: 0 16px;
            line-height: 40px;
            cursor: pointer;
            font-size: 13px; } } }

      .MuiCollapse-container {
        grid-column: 1/5;

        .title {
          grid-column: 1/2;
          justify-self: initial;
          align-self: auto; } }

      .MuiCollapse-wrapperInner {
        grid-template-columns: 120px 170px 1fr 1fr;
        column-gap: 24px;
        margin-top: 8px;

        input {
          width: 234px; }

        .title {
          height: 40px;
          line-height: 40px;

          .right_plate_info_item_icon {
            @include flex();
            align-self: center; } }

        .entrusted_select_with_btn {
          margin-left: -8px;

          .input_div {
            width: 234px; } } } }

    .dependencies {
      .secondRow {
        margin-top: 8px; }

      .thirdRow {
        margin-top: 8px;

        .dependenciesItem {
          @include flex(space-between, center);

          .content {
            font-size: 14px;
            line-height: 16px;
            color: $blue-main; }

          .deleteButton {
            @include size(32px, 32px);
            background-image: url('../../../img/x.svg');
            background-position: 1px 0;
            background-size: 30px;
            background-repeat: no-repeat;
            cursor: pointer; } } } } }

  .checklist {
    margin: 16px 0;

    .progressBar {
      display: flex;
      height: 24px;

      .digit_progress {
        font-size: 16px;
        margin-right: 8px;
        line-height: 24px;
        cursor: default; }

      .bar {
        position: relative;
        width: 100%;
        margin-top: 5px;

        .all_time {
          margin: inherit;
          position: absolute;
          width: 100%;
          height: 4px;
          background: rgba(27, 170, 240, 0.1);
          border-radius: 4px; }

        .completed {
          transition: .5s ease;
          margin: inherit;
          position: absolute;
          height: 4px;
          background: $blue-main;
          border-radius: 4px; } } }

    .header {
      color: $blue-main;
      display: flex;
      height: 40px;
      width: 100px;
      padding: 8px 12px;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 8px;
      cursor: pointer;

      .expandedCheckListItems {
        background-image: url('../../../img/up.svg');
        cursor: pointer; }

      .collapsedCheckListItems {
        background-image: url('../../../img/down.svg'); }

      div:first-child {
        padding-top: 4px; }

      div:last-child {
        width: 24px;
        height: 24px;
        background-position: 2px 1px;
        background-size: 20px;
        background-repeat: no-repeat; } }

    #checkItems {
      margin-top: 8px;

      .checkItem {
        display: flex;
        line-height: 18px;
        justify-content: space-between;

        .input_div {
          padding: 0 12px; }

        &:hover {
          .deleteButton {
            visibility: visible; } }

        .deleteButtonBox {
          display: flex;

          .deleteButton {
            align-self: center; } }

        .checkItemContent {
          display: flex;
          line-height: 32px;

          .checkbox_item {
            margin: 0;

            .checkbox_icon {
              @include size(30px, 30px);
              cursor: pointer;
              margin: 0;
              background-image: url('../../../img/checkbox empty.svg');
              background-repeat: no-repeat;
              background-size: 30px;

              &:hover {
                background-image: url('../../../img/checkbox ready.svg'); }

              &.checked {
                background-image: url('../../../img/checked1.svg'); } } }

          .orderNumber {
            margin-left: 8px;
            margin-right: 3px;
            cursor: default;

            &.asInput {
              line-height: 40px; } }

          .nameItem {
            cursor: pointer; } }

        .deleteButton {
          visibility: hidden;
          width: 16px;
          height: 16px;
          background-image: url('../../../img/x.svg');
          background-position: -2px -2px;
          background-size: 20px;
          background-repeat: no-repeat;
          cursor: pointer; } }

      #addCheckItem {
        margin-top: 8px;
        height: 40px;
        padding: 8px;
        display: flex;
        width: 150px;
        cursor: pointer;

        .addNewRowIcon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          background-image: url('../../../img/plus.svg'); }

        .addNewRowText {
          font-size: 14px;
          line-height: 25px;
          color: $blue-main; } } } }

  .full_width_components {
    .description {
      textarea {
        border-radius: 6px;
        position: relative;
        padding: 12px;
        width: 100%;
        max-height: 400px;
        font-family: Roboto,sans-serif;
        font-size: 14px;
        resize: none;
        line-height: 17px;
        cursor: pointer;
        overflow: auto !important;
        transition: .5s ease;

        &:focus {
          border: 1px solid $blue-main;
          cursor: text;
          padding: 12px; }

        &:not(:empty) {
          background-color: #F3FAFE;
          border: 1px solid #99D7F5; }

        &.collapsed {
          max-height: 120px; } }

      div.input_div {
        @include ellipsis(); } } } }

.dhx_header, .dhx_title ,.dhx_body {
  padding-left: 22px !important;
  color: #292A34;
  font-weight: normal !important;
  height: 100% !important; }

.dhx_header, .dhx_title {
  background: none !important; }

.dhx_footer {
  margin: 0; }
.dhx_title {
  display: none !important; }
//.just_event

.just_event {
  background: #AECBEC !important;
  color: #292A34;
  border-radius: 5px; }

.meeting-event {
  background-color: #9ADCF2;
  color: black;
  border-radius: 5px; }

.task_comp {
  background-color: #43A047 !important;
  .dhx_body {
    color: #FFFFFF !important; } }

.task_completed {
  background-color: #AAD6AC !important;
  color: #292A34; }

.task-event {
  // background-color: #E6E6E6 !important
  border-radius: 5px;
  color: #292A34; }

//.meeting-event

///

.task_card_overdue {
  color: red; }

.task_overdue {
  background-color: #E6E6E6;
  .dhx_body {
    color: red !important; } }

.task_comp {
  color: #FFFFFF; }

.dhx_footer {
  margin: 0; }
.dhx_cal_header, .dhx_cal_scale_placeholder {
  top: 0 !important;
  z-index: 10;
  height: 40px !important; }

.dhx_multi_day {
  display: none; }

.dhx_cal_data {
  top: 62px !important; }

.dhx_cal_header {
  border-left: 1px solid #E1E1E1;
  position: relative;
  overflow: visible;
  background: #F4F4F4 !important;
  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 19px);
    height: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    background: #E1E1E1;
    left: -19px;
    bottom: 0; } }

.dhx_scale_bar {
  background: transparent; }

.scale_bar {
  &__cell {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgba(41, 42, 52, 0.5);
    line-height: 24px;
    padding: 0 10px;
    &_day {
      align-items: flex-start; } } }

.scale_label {
  &__num {
    font-size: 28px;
    color: #292A34; } }

.dhx_scale_holder, .dhx_scale_holder_now {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABMCAYAAACs0907AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAaSURBVHgB7cAxAQAAAIIw+qekicbwcXALagoKWwOgYTwT+wAAAABJRU5ErkJggg=='); }

.dhx_scale_holder_now {
  background-color: transparent; }

.dhx_cal_select_menu::before {
  display: none; }

.dhx_now_time {
  opacity: 1;
  z-index: 10;
  color: #F44336;
  border-color: currentColor;
  pointer-events: none;
  &::before {
    content: '';
    position: absolute;
    background: currentColor;
    width: 11px;
    height: 11px;
    left: -6px;
    top: -4px;
    border-radius: 20px; } }

.scheduler-container .users-select .react-select__menu {
  z-index: 21; }

.event-type {
  &__TASK_TYPE {
    &.dhx_cal_select_menu {
      height: auto !important; }
    .icon_delete {
      display: none; }
    .dhx_event_resize {
      display: none !important; } }
  &__MEETING_TYPE {
    .dhx_event_resize {
      display: none !important; } } }

.disabled_event {
  pointer-events: none;
  color: #292A34;
  border-radius: 5px;
  opacity: 0.6; }

.disabled_delete_icon {
  .icon_delete {
    pointer-events: none;
    display: none; } }

.disabled_event_task {
  pointer-events: none;
  content: '';
  display: inline-block;
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: 4px 0px 0px 4px;
  .dhx_body {
    color: gray !important; } }

.day-cursor__move {
  .dhx_event_resize, .dhx_scale_holder {
    cursor: move !important; } }

.day-cursor__resize {
  .dhx_event_resize, .dhx_scale_holder {
    cursor: ns-resize !important; } }

$eventPriorities: ("in_work_priority": ("border": #D3D3D3), "average_priority": ("border": #FFD600), "urgent_priority": ("border": red), "high_priority": ("border": #FF9900));
$eventTypes: ("event-type__TASK_TYPE": ("back": #E6E6E6, "color": #292A34, "highlighted": #FFF, "shadow":0px 0px 58px 8px rgba(7, 84, 144, 0.953)), "event-type__MEETING_TYPE": ("back": #9ADCF2, "backOverdue": #D2F3FE, "color": black, "colorOverdue": #71748D),"event-type__EVENT_TASK_TYPE": ("back": #E6E6E6, "color": #292A34, "highlighted": #FFF, "shadow":0px 0px 58px 8px rgba(7, 84, 144, 0.953)), "event-type__EVENT_TYPE": ("color": #292A34, "colorOverdue": #71748D));
$eventStatuses: ("task_comp": ("back": #43A047, "color": #FFFFFF), "task_completed": ("back": #AAD6AC, "color": #292A34), "task_overdue": ("E6E6E6": #D3D3D3, "color": red));

$eventMargin: 2px;
.dhx_cal_event {
  background-color: transparent !important;
  padding: $eventMargin 0;
  border-color: transparent !important;
  box-shadow: none !important;
  overflow: hidden;
  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: calc(100% - #{$eventMargin * 2});
    position: absolute;
    left: 0;
    background: transparent !important;
    pointer-events: none;
    border-radius: 4px 0 0 4px; }
  &.dhx_cal_select_menu {
    border-radius: 5px;
    overflow: hidden;
    &:before {
      display: none; } }
  .dhx_body {
    border-radius: 5px;
    background-color: #E6E6E6;
    color: #292A34 !important;
    padding: 6px 5px 8px 8px;
    border: none !important;
    line-height: 16px !important;
    font-size: 16px;
    span {
      max-width: 100%;
      display: -webkit-box;
      line-clamp: 4;
      -webkit-line-clamp: 4;
      box-orient: vertical;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
      height: 64px;
      &.deadline {
        padding-left: 10px; } } }
  &.minute-diff {
    &__15 {
      .dhx_body {
        padding-top: 0;
        span {
          line-clamp: 1;
          -webkit-line-clamp: 1;
          height: 16px; } } }
    &__30 {
      .dhx_body {
        padding-top: 2px;
        span {
          line-clamp: 2;
          -webkit-line-clamp: 2;
          height: 32px; } } }
    &__45 {
      .dhx_body {
        padding-top: 2px;
        span {
          line-clamp: 3;
          -webkit-line-clamp: 3;
          height: 48px; } } } }
  @each $name, $priorityValue in $eventPriorities {
    &.#{$name}:before {
      background: map-get($priorityValue, "border") !important; } }
  @each $name, $typeValue in $eventTypes {
    &.#{$name} .dhx_body {
      color: map-get($typeValue, "color") !important;
      @if map-get($typeValue, "back") {
        background: map-get($typeValue, "back") !important; } }
    &.#{$name}.overdue-object .dhx_body {
      color: map-get($typeValue, "colorOverdue") !important;
      background: map-get($typeValue, "backOverdue") !important; }
    &.#{$name} {}
    &.highlighted .dhx_body {
      @if map-get($typeValue, "highlighted") {
        background: map-get($typeValue, "highlighted") !important;
        box-shadow: map-get($typeValue, "shadow") !important;
        color: black !important; } }
    &.dhx_body {
      background: map-get($typeValue, "back") !important; }

    &.#{$name} .dhx_body {
      color: map-get($typeValue, "color") !important; } }

  @each $name, $statusValue in $eventStatuses {
    &.#{$name} .dhx_body {
      color: map-get($statusValue, "color") !important;
      background: map-get($statusValue, "back") !important; } }
  &.event-type__EVENT_TYPE.overdue-object .dhx_body {
    opacity: 0.8; } }

.dhx_cal_event .dhx_footer {
  background: none !important; }

.dhx_cal_event .dhx_event_resize {
  margin-top: -3px !important;
  height: 4px; }

.dhx_cal_event.event-loader__active {
  pointer-events: none;
  filter: contrast(.5); }
.dhx_cal_event.event-loader__active:not(.dhx_cal_select_menu) {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 8px;
    width: 8px;
    border: 3px solid rgba(1, 176, 233, 1);
    border-radius: 50%;
    clip: rect(7px, 20px, 20px, 0);
    pointer-events: none;
    animation: loader-event-round-animation 1s infinite; } }

@keyframes loader-event-round-animation {
  from {
    transform: rotate(0); }
  from {
    transform: rotate(360deg); } }

#scheduler_here {
  user-select: none; }

.dhtmlx_btn-confirm_button div {
  background: #ff584c !important;
  color: white;
  border: 1px solid #ff584c !important;
  &:hover {
    background: red !important; } }

.dhtmlx_btn-cancel_button div {
  &:hover {
    background: #d9edf8 !important; } }
