.day-sidebar {
  position: relative;

  &__disabled-layout {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 96%;
    z-index: 100;
    backdrop-filter: grayscale(1);
  }
}
