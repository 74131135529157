.header {
    display: flex;
    width: 100%;
    height: 118px;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    border-bottom: 1px solid #d5cfcfa1; }

.scheduler-date-box {
    display: flex;
    width: 258px;
    max-height: 40px;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #929090; }

.scheduler-prev {
    width: 44px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E1E1E1;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer; }

.scheduler-next {
    width: 44px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E1E1E1;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer; }

.scheduler-date {
    height: 40px;
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-top: 1px solid #E1E1E1;
    border-bottom: 1px solid #E1E1E1;
    color: #292A34;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    white-space: nowrap; }

.view-title {
    height: 40px;
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #292A34; }
.scheduler-view-switcher {
    display: flex;
    height: 40px;
    justify-content: space-between;
    cursor: pointer; }

.scheduler-view-switcher__button {
    width: 101.9px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E1E1E1;
    border-radius: 0px; }

.scheduler-view-switcher__button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }

.scheduler-view-switcher__button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }

.scheduler-view-switcher .active {
    background-color: #01B0E9;
    color: white;
    border: 1px solid #01B0E9; }

.users_select-container {
    height: 40px;
    display: flex;
    gap: 15px; }
.users_select-title {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #292A34; }
.users-select {
    width: 248px;
    height: 40px;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    background: rgba(196, 196, 196, 0.15);
    color: #292A34; }
