@import '../../../sass/mixins';

.common-info-preloader {
  @include size(100vw, 100vh);
  @include flex(center, center);
  
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  will-change: opacity, visibility;
  
  &--show {
    opacity: 1;
    visibility: visible;
  }
  
  &__content {
    @include size(100px, 59px);
    
    background-image: url('../../../img/login_logo.png');
    z-index: 1000;
    animation: preloder_jump 1.5s ease-in-out infinite;
    position: relative;
  }
  
  &__shadow {
    @include size(100px, 5px);
    
    left: 0;
    opacity: .7;
    bottom: -8px;
    background: #000000;
    border-radius: 100px/5px;
    animation: shadow_grow 1.5s ease-in-out infinite;
  }
}
