@import '../../../sass/variables';

.table-cell__preloader {
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  opacity: 0;
  visibility: hidden;
  
  &--show {
    opacity: 1;
    visibility: visible;
  }

  &--hidden {
    position: absolute;
    visibility: hidden;
  }
  
  > div {
    opacity: $preloaderSlowReqOpacity;

    > div {
      border-color: $blue-main transparent transparent transparent;
    }
  }
}