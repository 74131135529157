@import '../../../sass/mixins';
@import '../../../sass/variables';

.toggler {
    height: 40px;
    @include flex;
    .toggler_elem {
        border-top: 1px solid $blue-main;
        border-bottom: 1px solid $blue-main;
        border-right: 1px solid transparent;
        border-left: 1px dashed transparent;
        flex-grow: 1;
        text-align: center;
        padding: 8px;
        cursor: pointer;
        font-size: 15px;
        color: #A8A8A8;
        &:last-of-type {
            border-right: 1px solid $blue-main;
            border-radius: 0 6px 6px 0; }
        &:first-of-type {
            border-left: 1px solid $blue-main;
            border-radius: 6px 0 0 6px; }
        &.active_toggler_elem {
            border: 1px solid $blue-main;
            color: #212121;
            background: rgba(27, 170, 240, 0.1); } } }
