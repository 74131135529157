@import '../../../sass/mixins';

.file_elem {
    display: inline-block;
    margin: 4px;
    width: 80px;
    position: relative;
    .cross {
        right: 0;
        top: 0;
        opacity: 0; }
    &:hover {
        .cross {
            opacity: 1; } }
    img {
        display: block;
        margin: 4px auto 6px; }
    .file_name {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        text-align: center;
        display: block;
        text-decoration: none;
        @include ellipsis; } }
