@import '../../../../sass/variables';

.users-actions {
  
  
  &__count-users-wr {
    position: relative;
  }

  &__count-users-preloader {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 100;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &--show {
      opacity: 1;
      visibility: visible;
    }

    .preloader-local {
      opacity: $preloaderSlowReqOpacity;
      width: 14px;
      height: 14px;

      > div {
        border-color: $blue-main transparent transparent transparent;
        width: 14px;
        height: 14px;
        border-width: 1px;
      }
    }
  }

  &__count-users {
    color: $black-middle;
  }
}
