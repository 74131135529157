.timezone-select {

  
  &--top {
    .select_items {
      bottom: 0;
      top: -260px;
      transform: translateY(0);
    }
    
    .custom_select_users_item {
      &:first-child {
        color: inherit;
      }
    }
  }
}
