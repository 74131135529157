@import '../../../sass/mixins';



.user_avatar {
    overflow: hidden;
    border-radius: 50%;
    background-image: url('../../../img/beyo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    @include size(46px, 46px);
    &.with_avatar {
        background-image: none; }
    img {
        @include size(46px, 46px); } }

.checkbox_item .user_avatar {
    @include size(24px, 24px);
    background-size: cover;
    margin-right: 10px; }

.checkbox_item .user_avatar img {
    @include size(24px, 24px); }
