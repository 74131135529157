@import '../../../../sass/mixins';
@import '../../../../sass/variables';

.add_pannel_wrapper {

  .remove_confirmation {
    .wrapper {
      padding: 16px;
      height: inherit;
      position: relative;

      //.main_header
      //  height: 40px
      //  display: flex
      //  justify-content: space-between

      ////.dep_group_name
      ////  height: 40px
      ////
      ////  .input_div
      ////    height: 40px
      ////
      ////    > input
      ////      height: 38px

      ////.main_title
      ////  font-size: 20px
      ////  line-height: 24px
      ////  cursor: default
      ////  -webkit-touch-callout: none /* iOS Safari */
      ////  -webkit-user-select: none /* Safari */
      ////  -khtml-user-select: none /* Konqueror HTML */
      ////  -moz-user-select: none /* Old versions of Firefox */
      ////  -ms-user-select: none /* Internet Explorer/Edge */
      ////  user-select: none

      ////.close_button
      ////  @include size(24px, 24px)
      ////  cursor: pointer
      ////  background-image: url('../../../../img/x.svg')
      ////  background-position: -3px -3px
      ////  background-size: 30px
      ////  background-repeat: no-repeat

      .text {
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px; }

      .buttons_line {
        position: absolute;
        bottom: 16px;

        > div {
          padding: 0 16px;

          &:last-child {
            margin-left: 8px; } } } } }

  .remove_confirmation {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 20px rgba(41, 47, 76, 0.08);
    z-index: 3;
    color: $black-darkest;
    @include size(364px, 176px); }

  .add_users_plate {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 20px rgba(41, 47, 76, 0.08);
    z-index: 3;
    @include flex;
    flex-direction: column;
    color: $black-darkest;
    @include size(364px, auto);

    .edit_button {
      height: 32px;
      font-size: 13px;
      line-height: 16px;
      color: $blue-main;
      cursor: pointer;
      @include flex(flex-start, center); }

    .wrapper {
      //flex-grow: 1
      //padding: 24px 16px 8px
      //@include flex()
      //flex-direction: column
      height: inherit;
      overflow: auto;
      position: relative;
      padding: 16px;

      .main_header {
        height: 40px;
        display: flex;
        margin: 0;
        justify-content: space-between;

        .main_title {
          font-size: 16px;
          line-height: 24px;
          cursor: default;
          -webkit-touch-callout: none;
          /* iOS Safari */
          -webkit-user-select: none;
          /* Safari */
          -khtml-user-select: none;
          /* Konqueror HTML */
          -moz-user-select: none;
          /* Old versions of Firefox */
          -ms-user-select: none;
          /* Internet Explorer/Edge */
          user-select: none; }

        .close_button {
          @include size(24px, 24px);
          cursor: pointer;
          background-image: url('../../../../img/x.svg');
          background-position: -3px -3px;
          background-size: 30px;
          background-repeat: no-repeat; } }

      .header {
        cursor: default;
        height: 32px;
        font-size: 14px;
        line-height: 32px;
        font-weight: 500;
        color: $black-darkest;
        @include flex(space-between, center);
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;

        > div {
          color: $blue-main;
          font-weight: normal;
          margin-right: 8px; } }


      .user_checkbox {
        height: 300px;
        overflow: auto;
        margin-top: 8px; }


      .dep_group_name {
        //height: 40px
        font-size: 16px;
        line-height: 24px; }


      .buttons_line {
        //align-items: end
        //position: absolute
        margin-top: 8px;
        //bottom: 0
        @include flex();
        justify-content: space-between;
        height: 40px;

        .leftPart {
          @include flex();

          > div {
            padding: 0 16px;

            &:last-child {
              margin-left: 8px; } } }

        .rightPart {
          .button {
            padding: 0 12px; } } }


      .search {
        margin: 0; } }

    // span
    //   color: #5F6368
    //   margin: 24px 0 8px
    //   display: block

    .search {
      margin: 16px 0; }

    .action_btns {
      height: 57px;
      box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
      @include flex(space-around, center);

      & > div {
        width: 94px; }

      .back {
        color: $blue-main;
        cursor: pointer; } }

    .radio {
      height: 256px; } } }
