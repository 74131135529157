@import '../../sass/variables';

.main {
  
  
  &__header {
    
  }

  &__header-left {
    
  }

  &__header-right {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 32px;
  }

  &__header-right-preloader {
    background: #01b0e9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &--show {
      opacity: 1;
      visibility: visible;
    }

    .preloader-local {
      opacity: $preloaderSlowReqOpacity;
    }
  }

  &__header-notification {
    &:hover {
      .notification-window__ico-wr {
        transform: scale(1.1);
      }
    }
  }

  &__header-avatar {
    margin-left: 18px;

    &:hover {
      .avatar {
        transform: scale(1.1);
      }
    }

    .avatar {
      transition: transform 0.25s ease-in-out;
    }
  }
}
