@import '../../../sass/mixins';
@import '../../../sass/variables';


.checkbox_item {
    margin-bottom: 16px;
    cursor: pointer;
    @include flex(false, center);
    .checkbox_icon {
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        margin-right: 10px;
        @include size(18px, 18px); }
    .beyollaw {
        margin-right: 10px;
        @include size(24px, 24px); }
    .checkbox_text {
        font-size: 16px;
        color: #000000;
        @include ellipsis; }
    .checked {
        border-color: $blue-main;
        background: url('../../../img/checked1.svg') center; } }
