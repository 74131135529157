@import '../../../../sass/variables.sass';

.section-select {
  position: relative;
  
  &--error {
    
  }

  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    
    &--show {
      opacity: 1;
      visibility: visible;
    }
  }
  
  &__select {
    
  }

  &__add-btn {
    color: $blue-main;
    margin-top: 5px;
    background: none;
    transition: all 0.25s ease-in-out;
    
    &:hover {
      color: $black-darkest;
    }
  }

  &__error-messages {
    
  }
}
