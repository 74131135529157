@import '../../../sass/mixins';
@import '../../../sass/variables';

.columns_select_window_item {
    margin: 9px 0;
    cursor: pointer;

    letter-spacing: 0.2px;
    @include flex(space-between);
    &.disabled {
        //background: $disabled
        //color: rgba(0, 0, 0, 0.87)
        //color: $disabled_text
        pointer-events: none;
        .checkbox_text {
            color: rgba(0, 0, 0, 0.87) !important;
            opacity: 0.5; }
        .rows_icon {
            background: url('../../../img/bi_layout-three-columns_grey.svg') left; } }

    .checkbox_icon {
        border: 1px solid rgba(0, 0, 0, 0.34);
        border-radius: 3px;
        //margin-right: 10px
        @include size(18px, 18px); }
    .beyollaw {
        margin-right: 10px;
        @include size(24px, 24px); }
    .checkbox_text {
        letter-spacing: 0.2px;
        font-size: 14px;
        line-height: 20px;
        //color: #000000
        @include ellipsis;
        &.active {
            color: $blue-main; } }
    .checked {
        border-color: $blue-main;
        background: url('../../../img/checked1.svg') center; }
    .desc_box {
        display: flex;
        .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            color: $blue-main; }
        &.with_col_icon {
            .checkbox_text {
                color: $blue-main; } } }
    .rows_icon {
        background: url('../../../img/bi_layout-three-columns_blue.svg') left;
        width: 24px;
        margin-right: 5px; } }
